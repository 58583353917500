import Cookies from 'js-cookie';

import * as actionTypes from '../actions/actionTypes';
import {TOKEN} from '../config/constants';


const initialState = {
  isAuth: !!Cookies.get(TOKEN),
}

const authState = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.LOG_IN:
    const data = action.payload.res.data;
    Cookies.set(TOKEN, data.token);
    return {
      ...state,
      ...{isAuth: true}
    };
  case actionTypes.LOG_OUT:
    Cookies.set(TOKEN, '');
    return {
      ...state,
      ...{isAuth: false}
    };
  default:
    return state;
  }
}

export default authState
