import React from 'react';
import ReactDOM from 'react-dom/client';
import 'core-js';

import './index.css';
import App from './App';
import {Provider} from 'react-redux'
import store from './store/store'
import axios from "axios";
import Cookies from "js-cookie";
import {TOKEN} from "./store/config/constants";
import * as actionTypes from './store/actions/actionTypes';
import {ENUM_STATUS} from "./store/config/enums";
import nProgress from 'nprogress';
import 'nprogress/nprogress.css'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App/>
  </Provider>
);

const {dispatch} = store; // direct access to redux store.

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization, *';
/*if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}*/

// before a request is made start the nprogress
axios.interceptors.request.use(config => {
  // dispatch({type:actionTypes.STATUS, payload:{status:ENUM_STATUS.loading}});
  const token = Cookies.get(TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  nProgress.start();
  return config
})

// before a response is returned stop nprogress
axios.interceptors.response.use(response => {
  dispatch({type: actionTypes.STATUS, payload: {status: ENUM_STATUS.succeeded}});
  nProgress.done();
  return response;
}, error => {
  nProgress.done();
  if (typeof error.response != 'undefined' && error.response.status === 401) {
    dispatch({type: actionTypes.LOG_OUT, payload: {}})
  } else {
    try {
      const data = error.response.data;
      if (data && data.type === "fail") {

        Object.entries(data.errors).forEach(([i, e]) => {
          let title = i;
          let count_array = Array(5).fill().map((_, i) => 1);
          if (typeof count_array.find(e => title === e) === "undefined")
            title = "Error";
          dispatch({
            type: actionTypes.TOAST, payload: {
              type: "danger",
              msg: e,
              title: title
            }
          })
          dispatch({type: actionTypes.STATUS, payload: {status: ENUM_STATUS.failed}});
        })
      }
    } catch (e) {
      console.log(e);
    }
  }
  // dispatch({type: actionTypes.TOAST, payload: {
  //     msg:[]
  //     }})
  // Swal.fire({
  //   title: title,
  //   html: text,
  //   icon: "error",
  //   confirmButtonText: "Cool",
  // });
  return error
},)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
