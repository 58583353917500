import React, {Component} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import './scss/style.scss'
import {CToaster} from "@coreui/react";
import {connect} from "react-redux";
import PropTypes from 'prop-types'
import routes from "./routes";
import PrivateRoute from "./components/hoc/ProtectedRoute";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"/>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))

// const [toast, addToast] = useState(0)
// const toaster = useRef()

class App extends Component {
  constructor(props) {
    super(props);
    this.toaster = React.createRef();
  }

  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login" element={<Login/>}/>
            <Route exact path="/500" name="Page 500" element={<Page500/>}/>
            {routes.map((route, idx) => {
              return (route.component && (<Route key={idx}
                path={route.path}
                name={route.name}
                element={<DefaultLayout>
                  <PrivateRoute>
                    <route.component/>
                  </PrivateRoute>
                </DefaultLayout>}
              />))
            })}
            <Route path="*" name="Page 404" element={<Page404/>}/>
          </Routes>
        </React.Suspense>
        <CToaster ref={this.toaster} push={this.props.toast} placement="top-end"/>
      </BrowserRouter>
    )
  }
}

const mapStateToPops = state => {
  return {
    toast: state.basic.toast
  }
}


App.propTypes = {
  children: PropTypes.node,
  toast: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number
  ]),
}

export default connect(mapStateToPops)(App)
