import {createStore, combineReducers, applyMiddleware} from 'redux';

import authReducer from './reducers/auth';
import basicReducer from './reducers/basic';
import thunk from "redux-thunk";
//store

//reducer
const rootReducer = combineReducers({
  auth: authReducer,
  basic: basicReducer,
});

// dispatching action

const logger = store => {
  return next => {
    return action => {
      const result = next(action);
      // console.log("Middelware", store.getState());
      return result;
    }
  }
};


const store = createStore(rootReducer, applyMiddleware(logger, thunk))

//subscription


export default store
