import React from "react";
import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";

const PrivateRoute = ({children}) => {

  const redirectTo = window.location.pathname;
  const isAuth = useSelector((state) => state.auth.isAuth)
  return isAuth ? (
    <>{children}</>
  ) : (
    <Navigate
      to={`/login?redirectTo=${redirectTo}`}
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
}

export default PrivateRoute;

