import * as actionTypes from '../actions/actionTypes';
import {CToast, CToastBody, CToastHeader} from "@coreui/react";
import React from "react";
import {ENUM_STATUS} from "../config/enums";

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: true,
  toast: 0,
  status: ENUM_STATUS.idle,
}

const setClasses = (type) => {

  switch (type) {
  case "warning":
    return "";
  default:
    return "text-white";
  }
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.SET:
    return {...state, ...action.payload}
  case actionTypes.STATUS:
    return {...state, status: action.payload.status}
  case actionTypes.TOAST:
    const toastLayout = (<CToast color={action.payload.type} className={setClasses(action.payload.type)}>
      <CToastHeader closeButton>
        {/*<svg
                        className="rounded me-auto"
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="xMidYMid slice"
                        focusable="false"
                        role="img"
                    >
                    <rect width="100%" height="100%" fill={getColor(action.payload.type)}></rect>
                    </svg>*/}
        <strong className="me-auto">{action.payload.title.toUpperCase()}</strong>
        {/*<small>7 min ago</small>*/}
      </CToastHeader>
      <CToastBody>{action.payload.msg}</CToastBody>
    </CToast>);
    return {...state, ...{toast: toastLayout}}
    // return state;
  default:
    return state
  }
}

export default changeState;
